import React, { useState } from "react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const Home = ({ data }) => {
  const [step, setStep] = useState(0)
  const [imgs, setImgs] = useState([...data.allImageSharp.edges])

  const h2 = {
    color: "#a7a4a4",
    maxWidth: "720px",
    padding: "12px",
  }

  const h1 = {
    marginTop: "12px",
    padding: "12px",
  }

  const vote = name => {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Image",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: name.split(".")[0],
    })
    setStep(step + 1)
  }

  const getStarted = () => {
    let i = -1
    if (window.ga) {
      window.ga(function (tracker) {
        i = 1
      })
    }
    setImgs(shuffle(imgs))
    setStep(i)
  }

  return (
    <div className="container full-height flex flex-column">
      <h1 style={h1}>Minuit Céramique</h1>
      {step === -1 && (
        <div className="flex flex-column flex-grow flex-center align-center">
          <h2 style={h2}>
            Votez pour votre mug préféré
            <br />
            en cliquant sur une image parmi les quatre qui apparaîtront
          </h2>
          <p style={{ paddingBottom: "12px" }}>
            Il semble que votre navigateur bloque Google Analytics.
            <br /> Veuillez autoriser Google Analytics pour pouvoir voter.
          </p>
          <button onClick={getStarted}>{"Je vote →"}</button>
        </div>
      )}
      {step === 0 && (
        <div className="flex flex-column flex-grow flex-center align-center">
          <h2 style={h2}>
            {"Votez pour votre mug préféré"}
            <br />
            {"en cliquant sur une image parmi les quatre qui apparaîtront"}
          </h2>
          <button onClick={getStarted}>{"Je vote →"}</button>
        </div>
      )}
      {step > 0 && step < 6 && (
        <div>
          <p style={{ textAlign: "right", paddingRight: "6px" }}>{step}/7</p>
          <div>
            {imgs
              .filter((el, i) => i >= (step - 1) * 4 && i < step * 4)
              .map((el, i) => (
                <div
                  key={i}
                  className="imgVote"
                  onClick={() => vote(el.node.fluid.src.split("/").pop())}
                  role="button"
                  tabIndex={0}
                >
                  <Img fluid={el.node.fluid} />
                </div>
              ))}
          </div>
        </div>
      )}
      {step === 6 && (
        <div className="flex flex-column flex-grow flex-center align-center">
          <h2 style={h2}>
            {"Merci beaucoup et rendez-vous le 6 avril pour le lancement de la précommande !"}
            <br />
          </h2>
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  query {
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 430) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default Home
